<template>
  <v-container>
    <breadcrumb :items="breadcrumb" />
    <h1 v-html="title"></h1>
    <p v-html="content"></p>

    <v-row class="my-4">
      <v-col cols="12" md="4" sm="6" v-for="item in categories" :key="item.id">
        <v-card
          elevation="3"
          class="faq-card"
          :to="{ name: 'FaqList', params: { categoryName: item.slug } }"
        >
          <v-flex class="d-flex">
            <v-avatar tile>
              <img :src="item.immagine.guid" alt="Immagine categoria" />
            </v-avatar>
            <div>
              <v-card-title><strong v-html="item.name"></strong></v-card-title>
              <v-card-subtitle>{{ item.description }}</v-card-subtitle>
            </div>
          </v-flex>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
img.size-full {
  width: 100%;
}
.v-avatar {
  margin: 5px;
  margin-left: 15px;
  height: 65px !important;
  min-width: 65px !important;
  width: 65px !important;
  top: 14px;
}
.faq-card {
  min-height: 100px;
  height: 100%;
  border-radius: 8px;
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import page from "~/mixins/page";
import CMSService from "~/service/cmService";
export default {
  components: { Breadcrumb },
  name: "FaqCategory",
  mixins: [page],
  jsonld() {
    if (this.categories) {
      var items = this.categories.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: process.env.VUE_APP_EBSN_URL + "/faq/" + item.slug
      }));
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: items
      };
    }
  },
  metaInfo() {
    return {
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: window.location.href
        }
      ]
    };
  },
  data() {
    return {
      categories: null
    };
  },
  mounted() {
    this.page = this.$route.params.page;
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      const res = await CMSService.getCustomPostByFilters("categoria_faq");

      if (res && res.data && res.data.length) {
        this.categories = res.data;
      }
    }
  }
};
</script>
